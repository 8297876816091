var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c(
        "div",
        { staticClass: "head-search" },
        [
          _c("head-layout", { attrs: { "head-title": "知识搜索" } }),
          _c(
            "div",
            { staticClass: "head-right" },
            [
              _c(
                "el-input",
                {
                  ref: "searchInput",
                  staticClass: "input-with-select",
                  attrs: { placeholder: "请输入知识名称", clearable: "" },
                  on: {
                    focus: function ($event) {
                      _vm.showHistory = true
                    },
                    input: _vm.searchWord,
                  },
                  nativeOn: {
                    mouseleave: function ($event) {
                      return _vm.inputBlur.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.fileName,
                    callback: function ($$v) {
                      _vm.fileName = $$v
                    },
                    expression: "fileName",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "prepend" },
                    [
                      _c("avue-input-tree", {
                        attrs: {
                          props: _vm.inputTreeProps,
                          "default-expand-all": "",
                          placeholder: "请选择",
                          dic: _vm.dic,
                        },
                        on: { change: _vm.chooseKnowledge },
                        model: {
                          value: _vm.knowledgeValue,
                          callback: function ($$v) {
                            _vm.knowledgeValue = $$v
                          },
                          expression: "knowledgeValue",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-button", {
                    staticStyle: { color: "#FFFFFF" },
                    style: { backgroundColor: _vm.themeColor },
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: { click: _vm.searchTableList },
                    slot: "append",
                  }),
                ],
                2
              ),
              _c(
                "el-radio-group",
                {
                  on: { input: _vm.changeType },
                  model: {
                    value: _vm.showType,
                    callback: function ($$v) {
                      _vm.showType = $$v
                    },
                    expression: "showType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "card" } }, [
                    _vm._v("卡片"),
                  ]),
                  _c("el-radio", { attrs: { label: "list" } }, [
                    _vm._v("列表"),
                  ]),
                ],
                1
              ),
              _vm.fileName && _vm.showSearchList && _vm.searchList.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "history-dialog",
                      on: {
                        mouseleave: function ($event) {
                          _vm.showSearchList = false
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "max-height": "500px",
                            "overflow-y": "auto",
                          },
                        },
                        _vm._l(_vm.searchList, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "history-content",
                              on: {
                                click: function ($event) {
                                  return _vm.clickSearch(item.title)
                                },
                              },
                            },
                            [_c("span", [_vm._v(_vm._s(item.title))])]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.fileName && _vm.showHistory && _vm.browseList.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "history-dialog",
                      on: {
                        mouseleave: function ($event) {
                          _vm.showHistory = false
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "max-height": "500px",
                            "overflow-y": "auto",
                          },
                        },
                        _vm._l(_vm.browseList, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "history-content",
                              on: {
                                click: function ($event) {
                                  return _vm.clickSearch(item.fileName)
                                },
                                mousemove: function ($event) {
                                  return _vm.showClear(item)
                                },
                                mouseleave: function ($event) {
                                  return _vm.hideClear(item)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  style: {
                                    color: item.show ? _vm.themeColor : "",
                                  },
                                },
                                [_vm._v(_vm._s(item.fileName))]
                              ),
                              item.show
                                ? _c(
                                    "span",
                                    {
                                      style: { color: _vm.themeColor },
                                      attrs: { underline: false },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.removeHistory(
                                            item.browseId,
                                            "one"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("清除")]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                      _c("div", { staticClass: "btns" }, [
                        _c(
                          "span",
                          {
                            style: { color: _vm.themeColor },
                            attrs: { underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.removeHistory("", "all")
                              },
                            },
                          },
                          [_vm._v("清除历史")]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tag-content" },
        [
          _vm.showClassification
            ? _c("knowledge-classification", {
                ref: "knowledgeClass",
                on: { "select-tag": _vm.selectTag },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.showType == "list"
        ? _c("grid-layout", {
            ref: "gridLayOut",
            attrs: {
              "table-options": _vm.tableOptions,
              "table-data": _vm.tableData,
              "table-loading": _vm.tableLoading,
              "data-total": _vm.page.total,
              page: _vm.page,
            },
            on: {
              "page-current-change": _vm.onLoad,
              "page-size-change": _vm.onLoad,
              "grid-row-detail-click": _vm.detail,
            },
          })
        : _vm._e(),
      _vm.showType == "card"
        ? _c(
            "div",
            { staticClass: "cardBigBox" },
            [
              _c(
                "div",
                { staticClass: "card-content" },
                _vm._l(_vm.tableData, function (item, index) {
                  return _c(
                    "el-card",
                    {
                      key: item.id,
                      staticClass: "box-card",
                      style: { width: _vm.cardWidthType ? "32%" : "" },
                    },
                    [
                      _c("div", { staticClass: "box-head" }, [
                        _c(
                          "div",
                          {
                            staticClass: "head-item",
                            staticStyle: { "justify-content": "flex-start" },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "text",
                                size: "medium",
                                icon: item.collect
                                  ? "el-icon-star-on"
                                  : "el-icon-star-off",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clickCollect(item)
                                },
                              },
                            }),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "12px" } },
                              [_vm._v(_vm._s(item.collectCount))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "head-item",
                            staticStyle: { "justify-content": "flex-end" },
                          },
                          [
                            _c("i", { staticClass: "el-icon-view" }),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "12px" } },
                              [_vm._v(_vm._s(item.browseCount))]
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.detail(item)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "img-box" }, [
                            item.extension == "mp4"
                              ? _c(
                                  "video",
                                  {
                                    ref: "video",
                                    refInFor: true,
                                    staticClass:
                                      "video-js vjs-default-skin vjs-big-play-centered mt10",
                                    attrs: { id: "myVideos" + index },
                                  },
                                  [
                                    _c("source", {
                                      attrs: {
                                        src: item.attachList[0].link,
                                        type: "video/mp4",
                                      },
                                    }),
                                  ]
                                )
                              : _c("img", {
                                  attrs: { src: item.fileCover, alt: "" },
                                }),
                          ]),
                          _c("p", [_vm._v(_vm._s(item.fileName))]),
                          _c("p", [_vm._v(_vm._s(item.updateTime))]),
                        ]
                      ),
                    ]
                  )
                }),
                1
              ),
              _c("el-pagination", {
                staticStyle: { "margin-top": "12px", "margin-right": "12px" },
                attrs: {
                  background: "",
                  "current-page": _vm.page.currentPage,
                  "page-sizes": [10, 20, 30, 40, 50, 100],
                  "page-size": _vm.page.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.page.total,
                },
                on: {
                  "size-change": _vm.sizeChange,
                  "current-change": _vm.currentChange,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }