<template>
  <div style="height: 100%;">
    <div class="head-search">
      <head-layout head-title="知识搜索"></head-layout>
      <div class="head-right">
        <el-input class="input-with-select" ref="searchInput" placeholder="请输入知识名称" clearable v-model="fileName"
          @focus="showHistory = true" @input="searchWord" @mouseleave.native="inputBlur">
          <template slot="prepend">
            <avue-input-tree :props="inputTreeProps" default-expand-all v-model="knowledgeValue" placeholder="请选择"
              :dic="dic" @change="chooseKnowledge"></avue-input-tree>
          </template>
          <el-button slot="append" icon="el-icon-search" @click="searchTableList" :style="{backgroundColor:themeColor}" style="color: #FFFFFF"></el-button>
        </el-input>
        <el-radio-group v-model="showType" @input="changeType">
          <el-radio label="card">卡片</el-radio>
          <el-radio label="list">列表</el-radio>
        </el-radio-group>
        <div class="history-dialog" v-if="fileName && showSearchList && searchList.length > 0"
          @mouseleave="showSearchList = false">
          <div style="max-height: 500px; overflow-y: auto">
            <div class="history-content" v-for="item in searchList" :key="item.id" @click="clickSearch(item.title)">
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
        <div class="history-dialog" v-if="!fileName && showHistory && browseList.length > 0"
          @mouseleave="showHistory = false">
          <div style="max-height: 500px; overflow-y: auto">
            <div class="history-content" v-for="item in browseList" :key="item.id" @click="clickSearch(item.fileName)"
              @mousemove="showClear(item)" @mouseleave="hideClear(item)">
              <span :style="{ color: item.show ? themeColor : '' }">{{ item.fileName }}</span>
              <span :style="{ color: themeColor }" :underline="false" @click.stop="removeHistory(item.browseId, 'one')"
                v-if="item.show">清除</span>
            </div>
          </div>
          <div class="btns">
            <span :style="{ color: themeColor }" :underline="false" @click="removeHistory('', 'all')">清除历史</span>
          </div>
        </div>
      </div>
    </div>
    <div class="tag-content">
      <knowledge-classification v-if="showClassification" ref="knowledgeClass"
        @select-tag="selectTag"></knowledge-classification>
    </div>
    <grid-layout v-if="showType == 'list'" ref="gridLayOut" :table-options="tableOptions" :table-data="tableData"
      :table-loading="tableLoading" :data-total="page.total" :page="page" @page-current-change="onLoad"
      @page-size-change="onLoad" @grid-row-detail-click="detail">
    </grid-layout>
    <div v-if="showType == 'card'" class="cardBigBox">
      <div class="card-content">
        <el-card class="box-card" :style="{width:cardWidthType?'32%':''}" v-for="(item, index) in tableData" :key="item.id">
          <div class="box-head">
            <div class="head-item" style="justify-content: flex-start;">
              <el-button type="text" size="medium" @click="clickCollect(item)"
                :icon="item.collect ? 'el-icon-star-on' : 'el-icon-star-off'"></el-button>
              <span style="margin-left: 12px;">{{ item.collectCount }}</span>
            </div>
            <div class="head-item" style="justify-content: flex-end;">
              <i class="el-icon-view"></i>
              <span style="margin-left: 12px;">{{ item.browseCount }}</span>
            </div>
          </div>
          <div @click="detail(item)">
            <div class="img-box">
              <video v-if="item.extension == 'mp4'" ref="video" :id="'myVideos' + index"
                class="video-js vjs-default-skin vjs-big-play-centered mt10">
                <source :src="item.attachList[0].link" type="video/mp4" />
              </video>
              <img v-else :src="item.fileCover" alt="" />
            </div>
            <p>{{ item.fileName }}</p>
            <p>{{ item.updateTime }}</p>
          </div>
        </el-card>
      </div>
      <el-pagination background @size-change="sizeChange" @current-change="currentChange" style="margin-top: 12px;margin-right: 12px;"
                     :current-page="page.currentPage" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="page.pageSize"
                     layout="total, sizes, prev, pager, next, jumper" :total="page.total">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import knowledgeClassification from "@/views/business/knowledge/components/knowledge-classification";
import { collect, listBrowse, removeBrowse, removeCollect, searchList, search, getKnowledgeClassifyTree } from "@/api/knowledgeClassifcation/knowledge";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      knowledgeType:'',
      tableData: [],
      showHistory: false,
      browseList: [], //浏览历史
      browseListAll: [],
      browsePage: {
        currentPage: 1,
        total: 0,
      },
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      fileName: "",
      fileTypeId: "",
      showType: "card",
      element: [],
      searchList: [],
      showSearchList: false,
      knowledgeValue: 'all',
      inputTreeProps: {
        label: "classifyName",
        value: "id",
      },
      dic: [],
      showClassification: false,
      cardWidthType:false
    };
  },

  watch: {
    themeColor(val) {
      this.themeColor = val;
    },
  },
  components: {
    knowledgeClassification,
    GridLayout,
  },

  computed: {
    ...mapGetters(["themeColor"]),
    ids() {
      let ids = [];
      this.browseListAll.forEach((ele) => {
        ids.push(ele.browseId);
      });
      return ids.join(",");
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        selection: false,
        menu: false,
        linklabel: "fileName",
        column: [
          {
            label: "名称",
            align: "left",
            prop: "fileName",
            overHidden: true,
          },
          {
            label: "收藏/浏览",
            align: "center",
            width: 170,
            prop: "collectCount",
            formatter: (row) => {
              return row.collectCount + "/" + row.browseCount;
            },
          },
          {
            label: "文档类型",
            align: "center",
            width: 120,
            prop: "extension",
          },
          {
            label: "上传时间",
            align: "center",
            width: 220,
            prop: "updateTime",
          },
        ],
      };
    },
  },

  mounted() {
    this.fileName = this.$route.query.search;
    this.initTree();
    this.onLoad(this.page);
    this.onLoadBrowse();
    window.addEventListener('resize', this.handleResize);
    if (window.innerWidth <= 1200) {
      this.cardWidthType = true;
    }
  },
  methods: {
    handleResize() {
      if (window.innerWidth <= 1200) {
        this.cardWidthType = true;
      } else {
        this.cardWidthType = false;
      }
    },
    initTree() {
      getKnowledgeClassifyTree()
        .then((result) => {
          this.dic = result.data.data;
          this.dic.unshift({id:'all',classifyName:'全部'})
        })
    },
    chooseKnowledge(e) {
      if (e) {
        this.showClassification = true;
        this.$nextTick(() => {
          this.$refs.knowledgeClass.showList = [];
          this.$refs.knowledgeClass.selectTags = [];
          if (e != 'all'){
            this.knowledgeType = e
          }else {
            this.knowledgeType = ''
          }
          this.selectTag(this.$refs.knowledgeClass.selectTags);
          if(e == 'all'){
            this.showClassification = false;
            this.onLoad(this.page);
          }else{
            this.$refs.knowledgeClass.getList(e);
          }
        })
      }
    },
    inputBlur() {
      this.$refs.searchInput.blur();
    },
    searchWord() {
      this.showSearchList = true;
      search(this.fileName).then((res) => {
        if (res.status == 200) {
          this.searchList = res.data;
        }
      });
    },
    changeType(val) {
      if (val == "card") {
        this.$nextTick(() => {
          this.initVideo();
        });
      } else {
        for (let index = 0; index < this.element; index++) {
          this.$videos(`myVideos${index}`).dispose();
        }
      }
    },
    initVideo() {
      let elementList = document.querySelectorAll(".video-js");
      this.element = elementList.length; //videojs 数量赋值
      for (let index = 0; index < elementList.length; index++) {
        let id = elementList[index].getAttribute("id");
        this.$videos(
          id,
          {
            autoplay: false, //自动播放
            //   muted:false, // 是否静音,
            controls: true, //控制条
            //   techOrder: ['html5','flash'],//设置flash播放
            language: "en", // 初始化语言
            preload: "auto", // 预加载
            width: "400",
            height: "200",
            // 倍速播放
            playbackRates: [0.5, 1, 1.5, 2],
          },
          function () {
            this.volume(0);
            this.one("playing", function () {
              // 监听播放
              console.log("成功初始化视频");
            });

            this.one("error", function (error) {
              // 监听错误
              console.error("监听异常", error);
            });
          }
        );
      }
    },
    detail(row) {
      window.open(window.location.origin + `#/knowledgeBrowse?id=${row.id}&fileName=${row.fileName}&isNewWindow=true`);
    },
    clickSearch(fileName) {
      this.fileName = fileName;
      this.onLoad(this.page);
    },
    searchTableList(){
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    showClear(item) {
      item.show = true;
    },
    hideClear(item) {
      item.show = false;
    },
    removeHistory(id, type) {
      let idList = [];
      let ids = ''
      let browseName = '';
      this.browseListAll.forEach((e)=>{
        if (e.browseId === id){
          browseName = e.fileName
        }
      })
      this.browseListAll.forEach((e)=>{
        if (browseName === e.fileName){
          idList.push(e.browseId)
        }
      })
      ids = idList.join(",")
      if (type == "all") {
        ids = this.ids;
      }
      removeBrowse(ids).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.showHistory = false;
          this.browsePage.currentPage = 0;
          this.onLoadBrowse();
        }
      });
    },
    onLoadBrowse() {
      listBrowse(this.browsePage.currentPage, 9999).then((res) => {
        const data = res.data.data;
        this.browseListAll = data.records;
        data.records = this.deWeightFour(data.records);
        this.browseList = data.records.slice(0, 10);
        this.browseList.forEach((item) => {
          this.$set(item, "show", false);
        });
        this.browsePage.total = data.total;
      });
    },
    deWeightFour(arr) {
      let obj = {};
      arr = arr.reduce(function (a, b) {
        obj[b.fileName] ? "" : (obj[b.fileName] = true && a.push(b));
        return a;
      }, []);
      return arr;
    },
    clickCollect(row) {
      if (row.myCollectList.length > 0) {
        removeCollect(row.myCollectList[0].id).then((res) => {
          if (res.data.code === 200) {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "取消收藏成功",
            });
          }
        });
      } else {
        let params = {
          knowInfoId: row.id,
          knowInfoCode: row.fileCode,
        };
        collect(params).then((res) => {
          if (res.data.code === 200) {
            this.onLoad(this.page);
            this.$message({
              message: "收藏成功",
              type: "success",
            });
          }
        });
      }
    },
    sizeChange(size) {
      this.page.pageSize = size;
      this.onLoad(this.page);
    },
    currentChange(current) {
      this.page.currentPage = current;
      this.onLoad(this.page);
    },
    onLoad(page) {
      this.showHistory = false;
      this.page = page;
      this.tableLoading = true;
      searchList(
        page.currentPage,
        page.pageSize,
        this.fileName,
        this.fileTypeId,
        this.knowledgeType
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        if (this.$refs.gridLayOut) {
          this.$refs.gridLayOut.page.total = data.total;
        }
        this.tableData = data.records;
        this.tableLoading = false;
        this.$forceUpdate();
      });
    },
    selectTag(list) {
      let fileTypeId = [];
      if (list.length > 0) {
        list.forEach((item) => {
          fileTypeId.push(item.id);
        });
      }
      this.fileTypeId = fileTypeId.toString();
      this.onLoad(this.page);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    for (let index = 0; index < this.element; index++) {
      this.$videos(`myVideos${index}`).dispose();
    }
  },
};
</script>

<style scoped lang='scss'>
.head-search {
  position: relative;

  .head-right {
    position: absolute;
    left: 30%;
    top: 6px;

    .el-input {
      width: 300px;
    }

    .el-radio-group {
      padding-left: 10px;
    }

    .history-dialog {
      width: 350px;
      position: absolute;
      top: 32px;
      padding: 12px;
      background: #fff;
      z-index: 999;
      border-left: 1px solid #DCDFE6;
      border-right: 1px solid #DCDFE6;
      border-bottom: 1px solid #DCDFE6;

      .history-content {
        line-height: 30px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;

        &:hover {
          background: #F5F7FA;
        }

        span {
          font-size: 14px;
          color: #606266;
          padding-left: 12px;
        }
      }

      .btns {
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
      }
    }
  }
}

.tag-content {
  padding: 5px 12px;
  background: #fff;
}

//::v-deep .avue-crud .el-table {
//  height: calc(100vh - 478px) !important;
//  max-height: calc(100vh - 478px) !important;
//}

.cardBigBox {
  background-color: #FFFFFF;
  height: calc(100vh - 177px);
}

.card-content {
  display: flex;
  flex-wrap: wrap;
  padding: 6px;
  height: calc(100% - 68px);
  overflow: auto;
  .box-card {
    width: calc(25% - 12px);
    cursor: pointer;
    margin: 6px;
    height: 388px;
    .box-head {
      display: flex;
      align-items: center;

      .head-item {
        flex: 1;
        line-height: 30px;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        padding: 0 12px;
      }
    }

    .img-box {
      height: 240px;

      img {
        width: 100%;
        height: 100%;
      }

      .video-js {
        width: 100%;
        height: 100%;
      }
    }

    p {
      font-size: 14px;
      text-align: center;
    }
  }
}

.el-pagination {
  text-align: right;
}

::v-deep .el-input-group__prepend .el-input {
  width: 100px !important;
}
::v-deep .el-input-group__prepend .el-input .el-input__inner{
  width: 100px !important;
}
::v-deep .input-with-select .el-input__inner {
  width: 240px;
  margin: 0;
}

::v-deep .avue-crud .el-table {
  height: calc(100vh - 230px) !important;
  max-height: calc(100vh - 230px) !important;
}
</style>
